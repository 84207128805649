<template>
  <div></div>
</template>

<script>
function mounted(){
  setTimeout(()=>{
    this.$router.push({ name: 'Dashboard' });
  }, 30000);
}
export default {
  name: 'Page404',
  mounted
}

</script>
<style>
  body{
    background-image: url('../../assets/image/page404.jpg');
    background-repeat-x: no-repeat;
		background-repeat-y: no-repeat;
	  background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    height: 100%;
    width: 100%;
  }
</style>